.popup__friends {
  max-width: 1320px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: baseline;
  justify-content: center;
  margin: 32px;
  padding: 32px;
  gap: 16px;
  background-color: #f2f2f2;
  overflow-y: auto;
  border-radius: 15px;
}

.popup-friends {
  align-items: normal;
}

.popup__friends-image {
  max-width: 250px;
  width: 100%;
  max-height: 250px;
  height: 100%;
  object-fit: contain;
}

.popup__friends-link:hover {
  opacity: 0.7;
  transition: 0.5s;
  transform: scale(1.1);
}
