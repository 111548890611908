.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFound__content {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.notFound__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  margin: 189px 30px 0;
  max-height: 607px;
}

.notFound__image {
  z-index: -1;
  position: relative;
  top: -175px;
  object-fit: contain;
  max-width: 1102px;
  margin: 0 58px;
  width: 100%;
}

.notFound__button {
  margin-bottom: 102px;
  padding: 16px 32px;
  background-color: #0fdace;
  border-radius: 15px;
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 1370px) {
  .notFound__title {
    font-size: 40px;
    line-height: 49px;
    margin-top: 134px;
  }

  .notFound__image {
    max-width: 754px;
    top: -100px;
  }

  .notFound__button {
    position: relative;
    top: -25px;
    padding: 16px 65px;
  }
}

@media screen and (max-width: 500px) {
  .notFound__title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 52px;
  }

  .notFound__image {
    top: 0;
  }

  .notFound__button {
    margin: 76px 0 141px;
    padding: 16px 50px;
  }
}

@media screen and (max-width: 320px) {
  .notFound__image {
    max-width: 294px;
    width: 100%;
    top: -4px;
    left: -13px;
  }

  .notFound__title {
    margin: 52px 0 0;
  }

  .notFound__button {
    font-size: 18px;
    line-height: 22px;
    margin-top: 72px;
    padding: 16px 72px;
  }
}
