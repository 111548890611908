.popup {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s, opacity 0.3s;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.feedback_form {
    padding: 20px;
}

.feedback_block {
    margin-top: 30px;
}

@media screen and (max-height: 577px) {
  .popup {
    align-items: flex-start;
    overflow-y: auto;
  }
  
}