.achievements {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 128px auto 0;
  column-gap: 36px;
}


@media screen and (max-width: 1440px) {
  .achievements {
    margin: 0 30px;
  }
}

@media screen and (max-width: 999px) {
  .achievements {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: 768px) {
  .achievements {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 500px) {
  .achievements {
    grid-template-columns: auto;
    margin-top: 85px;
    gap: 0;
    justify-content: center;
  }
}
