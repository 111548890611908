.friends {
  margin-top: 128px;
  margin-bottom: 167px;
}

.friends__title {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.friends__image {
  width: 305px;
  height: 305px;
  object-fit: contain;
}

.firends__button {
  all: unset;
  margin: 8px 0 23px 0;
  color: #515151;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  cursor: pointer;
}

.firends__button:hover {
  opacity: 0.8;
  transition: 0.5s;
}

.friends__slider {
  max-width: 1316px;
  max-height: 298px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .friends {
    margin: 131px 30px 157px;
  }

  .friends__image {
    width: 220px;
    height: 220px;
  }

  .firends__button {
    display: none;
  }

  .friends__slider {
    margin-top: 49px;
  }
}

@media screen and (max-width: 500px) {
  .friends {
    margin: 76px 65px 115px;
  }

  .friends__title {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 14px;
  }

  .firends__button {
    display: none;
  }

  .friends__image {
    width: 191px;
    margin: auto;
  }

  .friends__slider {
    gap: 10px;
    margin-top: 19px;
  }
}
