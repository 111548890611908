.body {
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: rgba(242, 242, 242, 1);
}

.pages {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
