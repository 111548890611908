.header {
  color: rgb(255, 255, 255);
  width: 100%;
  height: 80px;
  background-color: rgba(56, 56, 56, 1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1320px;
  width: 100%;
  height: 100%;
}

.header__logo {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
}

.header__burger-btn {
  display: none;
  all: unset;
}

.header__btn-image {
  display: none;
  width: 25px;
  height: 14px;
}

.header__navigation {
  display: flex;
  align-items: center;
}

.header__nav-list {
  display: grid;
  margin: 0;
  padding: 0;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 32px;
  list-style: none;
}

@media screen and (max-width: 1440px) {
  .header__logo {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .header__logo {
    margin-left: 47px;
  }
}

@media screen and (max-width: 920px) {
  .header__burger-btn {
    display: block;
    margin-right: 41px;
  }

  .header__btn-image {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .header__logo {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 32px;
  }
}
