.promo {
  color: rgb(0, 0, 0);
  width: 100%;
  height: 700px;
  position: relative;
  max-height: 620px;
}

.promo-background {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      103.85deg,
      rgba(242, 242, 242, 0.99) 35.22%,
      rgba(255, 255, 255, 0.8) 42.95%,
      rgba(203, 203, 203, 0.818877) 47.9%,
      rgba(188, 188, 188, 0.778365) 51.09%,
      rgba(137, 137, 137, 0.567558) 55.75%,
      rgba(0, 0, 0, 0) 77.14%
    ),
    url(https://www.kino-teatr.ru/movie/kadr/159786/1303742.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.promo-container {
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.promo-info {
  margin-top: 99px;
  max-width: 591px;
}

.promo-info__title {
  margin: 0;
  font-weight: 700;
  font-size: 70px;
  letter-spacing: normal;
}

.promo-info__subtitle {
  margin: 0;
  font-weight: 600;
  font-size: 29px;
  letter-spacing: 1px;
  text-align: center;
}

.promo-info__title_color {
  color: rgba(15, 218, 206, 1);
}

.promo-buttons {
  display: flex;
  margin-top: 69px;
}

.promo-buttons__button {
  border: rgba(15, 218, 206, 1), 1px, solid;
  margin-right: 40px;
  font-weight: 500;
  font-size: 24px;
  padding: 16px 32px;
  border-radius: 15px;
  transition: 0.3s;
  text-decoration: none;
  color: #000;
  text-align: center;
}

.promo-buttons__button:hover {
  transition: 0.5s;
  cursor: pointer;
  transform: scale(1.1);
}

.promo-buttons__button:active {
  transform: scale(0.9);
  transition: 0.5s;
}

.promo-buttons__button:last-child {
  margin-right: 0;
  background-color: rgba(255, 255, 255, 0);
}

.promo-buttons__button_color {
  background-color: rgba(15, 218, 206, 1);
}

.promo-buttons__button_color:hover {
  background-color: rgba(15, 218, 206, 0.7);
  border: rgba(15, 218, 206, 0.7), 1px, solid;
  box-shadow: 0 0 10px rgba(15, 218, 206, 0.7);
  text-shadow: 0 0 10px rgba(15, 218, 206, 0.7);
}

@media screen and (max-width: 1440px) {
  .promo {
    height: 447px;
  }

  .promo-info {
    margin-top: 82px;
  }

  .promo-background {
    max-height: 447px;
  }
  .promo-container {
    margin-left: 53px;
  }

  .promo-info__title {
    font-size: 50px;
    line-height: 61px;
  }

  .promo-info__subtitle {
    font-size: 21px;
    line-height: 25px;
    text-align: left;
  }

  .promo-buttons {
    margin-top: 77px;
  }

  .promo-buttons__button {
    text-align: center;
    margin-right: 18px;
    padding: 0 21px;
    align-content: center;
  }

  .promo-buttons__button:last-child {
    padding: 16px 42px;
  }
}

@media screen and (max-width: 500px) {
  .promo {
    overflow: hidden;
    height: 510px;
  }

  .promo-info {
    width: max-content;
    margin-top: 150px;
  }
  .promo-info__title {
    font-size: 36px;
  }

  .promo-background {
    position: relative;
    top: -163px;
    background-image: url(../../assets/images/preview_320.png);
    background-size: cover;
  }

  .promo-container {
    top: -415px;
    position: relative;
    margin: 0 auto;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .promo-info__subtitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.6px;
    margin-top: 2px;
  }

  .promo-buttons {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin-top: 22px;
    gap: 20px;
  }

  .promo-buttons__button {
    margin-right: 0;
    padding: 21px 46px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.5px;
  }
}
