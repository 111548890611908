.contactUs {
  margin-top: 128px;
}

.contactUs__title {
  margin: 30px 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}
.contactUs__form {
  background-color: #ececec;
  max-width: 1324px;
  border-radius: 30px;
}
.form__content {
  max-width: 1098px;
  margin: auto;
}
.form__data {
  display: grid;
  column-gap: 39px;
  grid-template-columns: auto auto auto;
}

.form__block {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}

.form__input-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}
.form__input {
  font-family: "Montserrat";
  height: 46px;
  padding: 0;
  border: 1px solid #afafaf;
  border-radius: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding-left: 12px;
}

.form__input_name {
  width: 326px;
}

.form__input_phone {
  width: 326px;
}

.form__input_email {
  width: 326px;
}

.form__input_comment {
  border: 1px solid #afafaf;
  border-radius: 15px;
  height: 155px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding-left: 12px;
}

.form__button {
  border-radius: 15px;
  background-color: #0fdace;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  border: none;
  padding: 16px 32px;
  cursor: pointer;
  margin-bottom: 40px;
  margin-top: 28px;
}

.form__button:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

.form__input-error {
  color: red;
  font-size: 12px;
  margin-top: 8px;
  min-height: 30px;
  max-width: 326px;
}

.form__input_comment-error {
  color: red;
  font-size: 12px;
  margin-top: 8px;
  min-height: 30px;
}

.form__placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 1440px) {
  .contactUs {
    margin: 128px 30px 0;
  }

  .form__content {
    max-width: 780px;
  }

  .form__block {
    margin-top: 36px;
    margin-bottom: 0;
  }

  .form__block:last-of-type {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .form__data {
    grid-template-columns: auto auto;
    column-gap: 18px;
  }

  .form__button {
    margin-top: 23px;
  }
}

@media screen and (max-width: 862px) {
  .contactUs {
    background-color: #ececec;
    padding: 32px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }

  .contactUs__form {
    border-radius: 0;
  }
}

@media screen and (max-width: 768px) {
  .contactUs__container {
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactUs__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form__content {
    display: flex;
    flex-direction: column;
  }

  .form__data {
    grid-template-columns: auto;
  }

  .form__button {
    max-width: 256px;
    align-self: center;
    font-size: 18px;
    line-height: 22px;
    padding: 16px 54px;
  }
}

@media screen and (max-width: 500px) {
  .form__input-error {
    max-width: 250px;
  }

  .form__input_name {
    width: 250px;
  }

  .form__input_phone {
    width: 250px;
  }

  .form__input_e-mail {
    width: 250px;
  }
}

@media screen and (max-width: 500px) {
  .contactUs {
    margin: 148px 0 0;
    background-color: #ececec;
  }

  .contactUs__form {
    background-color: #ececec;
  }
  .contactUs__title {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 4px;
  }

  .contactUs__form {
    max-width: 256px;
  }

  .form__data {
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 16px;
  }

  .form__block {
    margin: 0;
  }

  .form__input {
    max-width: 244px;
  }

  .form__input_e-mail {
    height: 70px;
  }

  .form__block:last-of-type {
    margin-top: 0;
  }

  .form__button {
    margin: 24px 0 0;
  }
}
