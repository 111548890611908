.events {
  margin-top: 128px;
}

.events__button {
  color: #515151;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 23px;
}

.events__button:hover {
  opacity: 0.5;
  transition: 0.5s;
}

.events__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  margin: 0 0 8px;
}

.events__block {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  max-width: 305px;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  margin-top: 0;
  margin: 0 auto 10px;
}

.events__icon {
  max-width: 305px;
  min-height: 372px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.events__date {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #515151;
  margin: 10px 0;
  width: 100%;
}

.events__name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 20px;
  width: 100%;
  max-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.events__link {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  border: 1px solid #0fdace;
  border-radius: 15px;
  padding: 12px 91px;
  margin-top: 4px;
  width: 100%;
}

.events__link:hover {
  cursor: pointer;
  transition: 0.5s;
  transform: scale(1.07);
}

.events__link:active {
  transform: scale(0.9);
  transition: 0.5s;
}

.my-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.my-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.my-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.my-dots li button:hover,
.my-dots li button:focus {
  outline: none;
}

.my-dots li button:hover:before,
.my-dots li button:focus:before {
  opacity: 1;
}

.my-dots li button:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-dots li.slick-active button:before {
  opacity: 0.75;
  color: #0fdace;
}

@media screen and (max-width: 1440px) {
  .events {
    margin: 161px 30px 0;
  }

  .events__button {
    display: none;
  }

  .events__icon {
    width: 305px;
    height: 372px;
  }
  .events__title {
    margin: 0 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .events {
    margin: 80px 32px 0;
  }

  .events__block {
    margin-top: 24px;
    max-width: 256px;
  }
  .events__title {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 0;
  }

  .events__button {
    display: none;
  }

  .events__icon {
    max-width: 256px;
    max-height: 312px;
  }

  .events__name {
    font-size: 18px;
    line-height: 27px;
  }

  .events__link {
    font-size: 24px;
    line-height: 29px;
    padding: 9px 67px;
  }

  .my-dots {
    bottom: -45px;
  }
}
